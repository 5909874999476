.about {
  width: 100%;
  height: 100%;
  background-color: #171a56;
  color: white; 
  padding: 80px 0px;
  position: fixed;
  z-index: 100;

  &.open {
    transition: 0.5s transform;
    z-index: 1000;
    transform: translate(0%, 0%);
    pointer-events: auto;
  }
  
  &.close {
    transition: 0.5s transform;
    z-index: 1000;
    transform: translate(100%, 0%);
    pointer-events: auto;
  }

  & .about-wrapper {
    max-width: 75%;
    margin: auto;
    height: inherit;
    overflow-y: auto;
  }

  & .close-about {
    position: absolute;
    right: 25px;
    top: 25px;
  }

  & .about-title {
    font-size: 32px;
  }

  & .about-subtitle {
    font-size: 22px;
  }

  & .about-paragraph {
    font-size: 18px;
  }
}

@media only screen and (max-width: 900px) {
  .about {
    padding: 0px;
    position: fixed;

    & .about-wrapper {
      padding: 30px 20px;
      margin: auto;
      overflow-y: scroll;
      height: inherit;
      max-width: 100%;
    }

    & .close-about {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    & .about-title {
      font-size: 48px;
    }

    & .about-subtitle {
      font-size: 28px;
    }

    & .about-paragraph {
      font-size: 24px;
      line-height: 30px;
    }
  }
}