.topic {
  width: 100%;
  text-align: center;
  font-size: 35px;
  transition: 1s all;
}

.value {
  width: 100%;
  text-align: center;
  font-size: 15px;
  opacity: 0.6;
}

.petal_no_data_0 {
  fill:#384F5B;
}
  
.petal_no_data_1{opacity:0.7;}

.st0 {
  fill: #c97d75;
}
.st1 {
  opacity: 0.7;
}

.shadow {
  opacity: 0.7;
  fill: url(#SVGID_1_);
}

$first-row-fall-time: 0.5s;
$second-row-fall-time: 1s;
$third-row-fall-time: 1.5s;
$fall-ease: cubic-bezier(0.175, 0.885, 0.32, 1.275);

$start-rotation: 81.6deg;
$rotation-angle: 13.6deg;
$rotation-time: 1s;
$rotation-ease: linear;

$first-row-delay-time: $first-row-fall-time - 0.2s;
$second-row-delay-time: $second-row-fall-time - 0.2s;
$third-row-delay-time: $third-row-fall-time - 0.2s;

#petal-1 {
  transform: rotate($start-rotation);
  animation: rotationPositive4 $rotation-time $first-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-2 {
  transform: rotate($start-rotation);
  animation: rotationPositive3 $rotation-time $first-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-3 {
  transform: rotate($start-rotation);
  animation: rotationPositive2 $rotation-time $first-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-4 {
  transform: rotate($start-rotation);
  animation: rotationPositive1 $rotation-time $first-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-5 {
  transform: rotate($start-rotation);
  animation: rotation0 $rotation-time $first-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-6 {
  transform: rotate($start-rotation);
  animation: rotationNegative1 $rotation-time $first-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-7 {
  transform: rotate($start-rotation);
  animation: rotationNegative2 $rotation-time $first-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-8 {
  transform: rotate($start-rotation);
  animation: rotationNegative3 $rotation-time $first-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-9 {
  transform: rotate($start-rotation);
  animation: rotationNegative4 $rotation-time $first-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-10 {
  transform: rotate($start-rotation);
  animation: rotationPositive5 $rotation-time $second-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-11 {
  transform: rotate($start-rotation);
  animation: rotationPositive4 $rotation-time $second-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-12 {
  transform: rotate($start-rotation);
  animation: rotationPositive3 $rotation-time $second-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-13 {
  transform: rotate($start-rotation);
  animation: rotationPositive2 $rotation-time $second-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-14 {
  transform: rotate($start-rotation);
  animation: rotationPositive1 $rotation-time $second-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-15 {
  transform: rotate($start-rotation);
  animation: rotation0 $rotation-time $second-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-16 {
  transform: rotate($start-rotation);
  animation: rotationNegative1 $rotation-time $second-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-17 {
  transform: rotate($start-rotation);
  animation: rotationNegative2 $rotation-time $second-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-18 {
  transform: rotate($start-rotation);
  animation: rotationNegative3 $rotation-time $second-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-19 {
  transform: rotate($start-rotation);
  animation: rotationNegative4 $rotation-time $second-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-20 {
  transform: rotate($start-rotation);
  animation: rotationNegative5 $rotation-time $second-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-21 {
  transform: rotate($start-rotation);
  animation: rotationPositive6 $rotation-time $third-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-22 {
  transform: rotate($start-rotation);
  animation: rotationPositive5 $rotation-time $third-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-23 {
  transform: rotate($start-rotation);
  animation: rotationPositive4 $rotation-time $third-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-24 {
  transform: rotate($start-rotation);
  animation: rotationPositive3 $rotation-time $third-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-25 {
  transform: rotate($start-rotation);
  animation: rotationPositive2 $rotation-time $third-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-26 {
  transform: rotate($start-rotation);
  animation: rotationPositive1 $rotation-time $third-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-27 {
  transform: rotate($start-rotation);
  animation: rotation0 $rotation-time $third-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-28 {
  transform: rotate($start-rotation);
  animation: rotationNegative1 $rotation-time $third-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-29 {
  transform: rotate($start-rotation);
  animation: rotationNegative2 $rotation-time $third-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-30 {
  transform: rotate($start-rotation);
  animation: rotationNegative3 $rotation-time $third-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-31 {
  transform: rotate($start-rotation);
  animation: rotationNegative4 $rotation-time $third-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-32 {
  transform: rotate($start-rotation);
  animation: rotationNegative5 $rotation-time $third-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

#petal-33 {
  transform: rotate($start-rotation);
  animation: rotationNegative6 $rotation-time $third-row-delay-time forwards;
  transition-timing-function: $rotation-ease;
  transform-origin: 45px 0;
  will-change: transform;
}

@keyframes rotationNegative6 {
  0% {
    transform: rotate($start-rotation);
  }
  100% {
    transform: rotate(-$rotation-angle * 6);
  }
}

@keyframes rotationNegative5 {
  0% {
    transform: rotate($start-rotation);
  }
  100% {
    transform: rotate(-$rotation-angle * 5);
  }
}

@keyframes rotationNegative4 {
  0% {
    transform: rotate($start-rotation);
  }
  100% {
    transform: rotate(-$rotation-angle * 4);
  }
}

@keyframes rotationNegative3 {
  0% {
    transform: rotate($start-rotation);
  }
  100% {
    transform: rotate(-$rotation-angle * 3);
  }
}

@keyframes rotationNegative2 {
  0% {
    transform: rotate($start-rotation);
  }
  100% {
    transform: rotate(-$rotation-angle * 2);
  }
}

@keyframes rotationNegative1 {
  0% {
    transform: rotate($start-rotation);
  }
  100% {
    transform: rotate(-$rotation-angle);
  }
}

@keyframes rotation0 {
  0% {
    transform: rotate($start-rotation);
  }
  100% {
    transform: rotate(0deg);
  }
}

@keyframes rotationPositive1 {
  0% {
    transform: rotate($start-rotation);
  }
  100% {
    transform: rotate($rotation-angle);
  }
}

@keyframes rotationPositive2 {
  0% {
    transform: rotate($start-rotation);
  }
  100% {
    transform: rotate($rotation-angle * 2);
  }
}

@keyframes rotationPositive3 {
  0% {
    transform: rotate($start-rotation);
  }
  100% {
    transform: rotate($rotation-angle * 3);
  }
}

@keyframes rotationPositive4 {
  0% {
    transform: rotate($start-rotation);
  }
  100% {
    transform: rotate($rotation-angle * 4);
  }
}

@keyframes rotationPositive5 {
  0% {
    transform: rotate($start-rotation);
  }
  100% {
    transform: rotate($rotation-angle * 5);
  }
}

@keyframes rotationPositive6 {
  0% {
    transform: rotate($start-rotation);
  }
  100% {
    transform: rotate($rotation-angle * 6);
  }
}

.petal-row-1 {
  transform: translate(0px, -500px);
  animation: gravity1 $first-row-fall-time forwards;
  transition-timing-function: $fall-ease;
}

.petal-row-2 {
  transform: translate(0px, -500px);
  animation: gravity2 $second-row-fall-time forwards;
  transition-timing-function: $fall-ease;
}

.petal-row-3 {
  transform: translate(0px, -500px);
  animation: gravity3 $third-row-fall-time forwards;
  transition-timing-function: $fall-ease;
}

@for $i from 0 through 33 {
  $randomNumber: random(1);
  $randomRotation: random(6) + deg;

  .petal-#{$i}.show {
    animation: movement-#{$i} forwards;
    animation-duration: $randomNumber + s;
  }

  @keyframes movement-#{$i} {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-$randomRotation);
    }
    75% {
      transform: rotate($randomRotation);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}

@keyframes gravity1 {
  0% {
    transform: translate(0px, -500px);
  }
  98% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes gravity2 {
  0% {
    transform: translate(0px, -500px);
  }
  98% {
    transform: translate(0px, 65px);
  }
  100% {
    transform: translate(0px, 65px);
  }
}

@keyframes gravity3 {
  0% {
    transform: translate(0px, -500px);
  }
  98% {
    transform: translate(0px, 126px);
  }
  100% {
    transform: translate(0px, 126px);
  }
}
