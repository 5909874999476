.landing {
  background-image: linear-gradient(#e1eaef, #e4f3ec);
  height: 100vh;
}

.landing-content {
  height: 70%;
  margin-top: 15%;

  & .author {
    font-size: calc(13px + (26 - 13) * ((100vw - 300px) / (1600 - 300)));
    font-family: 'Raleway';
    color: white;
  }

  & .title {
    font-size: calc(55px + (110 - 55) * ((100vw - 300px) / (1600 - 300)));
    color: white;
  }

  & .subtitle {
    font-size: calc(16px + (32 - 16) * ((100vw - 300px) / (1600 - 300)));
    color: white;
  }

  & .call-to-action {
    font-family: 'Raleway';
    color: white;
    font-size: calc(9px + (18 - 9) * ((100vw - 300px) / (1600 - 300)));
  }

  & .lighter-weight {
    font-weight: lighter;
    margin: 0 5px;
    color: white;
  }
}

@media only screen and (min-width: 768px) {
  .landing-content {
    height: 60%;
    max-width: 75%;
    position: absolute;
    top: -50px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
}

.landing-icon-container {
  width: 120px;
  height: 120px;
  border: 2px solid rgba(255, 255, 255, 1);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: pulse-border 2s infinite;
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  width: 160px;
  height: 160px;
}

@keyframes pulse-border {
  0% {
    border-color: rgba(255, 255, 255, 1);
  }
  50% {
    border-color: rgba(255, 255, 255, 0);
  }
  100% {
    border-color: rgba(255, 255, 255, 1);
  }
}

.landing-icon {
  transform: rotate(0deg);
  animation: rotation 240s infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.not-scrolling {
  background-position: 0 0%;
  transition: 2s background-position;
  transform: translateZ(0);
  will-change: background-position;
}

.scrolling {
  background-position: 0 100%;
  transition: 2s background-position;
  transform: translateZ(0);
  will-change: background-position;
}

$first-row-fall-time: 0.5s;
$second-row-fall-time: 1s;
$third-row-fall-time: 1.2s;
$up-time: 2s;
$fall-ease: cubic-bezier(0.175, 0.885, 0.32, 1.275);

.landing-content {
  // transform: translate(0px, -500px);
  // animation: gravity2 $second-row-fall-time forwards;
  // transition-timing-function: $fall-ease;
}

.landing-content.up {
  animation: gravity-up $up-time forwards;
  transition-timing-function: $fall-ease;
}

@keyframes gravity1 {
  0% {
    transform: translate(0px, -500px);
  }
  98% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes gravity2 {
  0% {
    transform: translate(0px, 0px);
  }
  98% {
    transform: translate(0px, -100px);
  }
  100% {
    transform: translate(1000px, -100px);
  }
}

@keyframes gravity3 {
  0% {
    transform: translate(0px, -500px);
  }
  98% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, 0px);
  }
}

@keyframes gravity-up {
  0% {
    transform: translate(0px, 0px);
  }
  100% {
    transform: translate(0px, -400px);
  }
}

.fadeOut {
  opacity: 0;
  transition: 1s opacity;
  will-change: opacity;
}
