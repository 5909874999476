.footer-el > div {
  color: #43449a;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
  font-size: 14px;
}

// .footer-el > div.active {
//   text-decoration: underline;
// }

.footer {
  background-color: white;
  box-shadow: 2px 0px 10px 1px #cecece;
  width: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
  position: fixed;
  bottom: 0;
}

@media only screen and (min-width: 900px) {
  .footer {
    top: 0;
  }
}

