.vx-axis-tick > text {
  font-family: Raleway;
  font-size: 13px;
  font-weight: 300;
}

.noteBox {
  height: 100px;
  position: fixed;
  bottom: 50px;
  width: 100%;
  color: white;
  z-index: 150;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2d2e7d;
  font-size: 20px;
}

@media only screen and (min-width: 900px) {
  .noteBox {
    height: calc(100% - 50px);
    position: fixed;
    bottom: auto;
    top: 50px;
    right: 0;
    left: auto;
    width: 12.5%;
    color: white;
    z-index: 150;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #43449a;
    font-size: 21px;
  }
}

.noteBox-close {
  position: absolute;
  top: 10px;
  right: 10px;
}
