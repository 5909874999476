#header {
  width: 100%; 
  position: fixed;
  display: flex;
  justify-content:  space-between;
  align-items: center;
  height: 30px;
  z-index: 99;
}

.header-el {
  width: 24%;
  height: 2px;
  background-color: #999999;
  transition: 1s all;
  opacity: 0.3;
}

.header-el.selected {
  background-color: #43449a;
  transition: 1s all;
  opacity: 1;
}

@media only screen and (min-width: 900px) {
  #header {
    position: fixed;
    top: 60px;
    width: 20px;
    left: 2px;
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
  }

  .header-el {
    width: 2px;
    height: 24%;
    background-color: #999999;
    transition: 1s all;
    opacity: 0.3;
  }
}

