body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(#e1eaef, #e4f3ec);
}

html, body, #root {
  height: 100%;
  width: 100%;
}

@media only screen and (min-width: 900px) {
  body {
    background: white;
  }
  body, #root {
    overflow: hidden;
    height: 100%;
  }
}

* {
  font-family: 'Cormorant Garamond', serif;
  box-sizing: border-box;
  color: black;
  /* outline: 1px solid gold; */
}

.raleway {
  font-family: 'Raleway', sans-serif;
}

.sourcesanspro {
  font-family: 'Source Sans Pro', sans-serif;
}

.pointer:active {
  opacity: 0.75;
}

.svg-hover:hover {
  opacity: 0.5 !important;
  transition: .5s opacity;
}

.svg-hover:active {
  opacity: 1;
  transition: .5s opacity;
}

.landing1,
.explore1,
.explore2,
.explore3,
.explore4,
.content1 {
  position: relative;
}

.page__inner,
.explore1,
.explore2,
.explore3,
.explore4 {
  height: 100%;
}

.explore4 {
  overflow: hidden;
}

.read-close {
  position: absolute;
  top: 35px;
  right: 35px;
}

.read-close-legend {
  position: absolute;
  bottom: 50px;
  left: 50%;
  margin-left: -70px;
}

@media only screen and (min-width: 900px) {
  .read-close-legend {
    bottom: 50%;
    margin-left: -90px;
    margin-bottom: -30px;
  }
}

/* Page */

.page {
  height: calc(100vh - 100px);
  /* padding: calc(15% + 1.5em) 5% 5%; */
  overflow-y: auto;
  padding-top: 30px;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  transition: transform 1s ease-in-out, box-shadow 1s ease-in-out;
}

.page h2 {
  font-size: 29px;
}

@media only screen and (min-width: 700px) {
  .page {
    height: calc(100vh - 50px);
    padding-top: 50px;
  }
}

@media only screen and (min-width: 900px) {
  .read {
    max-width: 75%;
    margin: 0 auto;
    padding-top: 75px;
  }
}

@media only screen and (min-width: 1023px) {
  .page {
    height: 100vh;
    padding-top: 50px;
  }
}

.flex {
  display: flex;
  flex: 0 1 auto;
}

h1 {
  font-size: 30px;
}

h4 {
  font-size: 14px;
  text-transform: uppercase;
}


@media only screen and (min-width: 900px) {
  h1 {
    font-size: 54px;
  }

  h4 {
    font-size: 24px;
  }

  p {
    font-size: 22px;
  }
}

.right-tap {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 50%;
  right: 15px;
  z-index: 999;
}

.left-tap {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 50%;
  left: 15px;
  z-index: 999;
}

@media only screen and (min-width: 900px) {
  .right-tap {
    right: -10px;
  }

  .left-tap {
    left: -10px;
  }
}

.view {
  height: 100vh;
  /* padding: calc(15% + 1.5em) 5% 5%; */
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  transition: transform 1s ease-in-out, box-shadow 1s ease-in-out;
}

.view-enter {
  transform: translate(-100%, 0);
}

.view-enter-active {
  transform: translate(0, 0);
}

.view-exit {
  box-shadow: 0 0 5em 0 rgba(0, 0, 0, 0.5) inset;
  transform: translate(100%, 0);
}

.page-enter {
  transform: translate(100%, 0);
}

.page-enter-active {
  transform: translate(0, 0);
}

.page-exit {
  box-shadow: 0 0 5em 0 rgba(0, 0, 0, 0.5) inset;
  transform: translate(-100%, 0);
}

.page-right.page-enter {
  transform: translate(-100%, 0);
}

.page-right.page-enter-active {
  transform: translate(0, 0);
}

.page-right.page-exit {
  box-shadow: 0 0 5em 0 rgba(0, 0, 0, 0.5) inset;
  transform: translate(100%, 0);
}

.page-exit .page__inner {
  opacity: 1;
  transform: scale(0.9);
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}

.menu {
  height: 100%;
  overflow: hidden;
  background-color: #2d2e7d;
  transform: translate(-100%, 0%);
  pointer-events: none;
  transition: 0.5s transform;
}

.menu.open {
  transition: 0.5s transform;
  z-index: 1000;
  transform: translate(0%, 0%);
  pointer-events: auto;
}

.menu.close {
  transition: 0.5s transform;
  z-index: 1000;
  transform: translate(-100%, 0%);
  pointer-events: auto;
}

.legend {
  transition: 0.5s transform;
  height: 100%;
  overflow: hidden;
  background-color: #2d2e7d;
  transform: translate(100%, 0%);
  pointer-events: none;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: fixed; 
  top: 0;
   bottom: 0;
   left: 0;
   right: 0;
}

.legend.open {
  z-index: 1000;
  transform: translate(0%, 0%);
  pointer-events: auto;
  transition: 0.5s transform;
}

.legend.close {
  z-index: 1000;
  transition: 0.5s transform;
  transform: translate(100%, 0%);
  pointer-events: auto;
}

@keyframes slideMenuIn {
  0% {
    opacity: 0;
    transform: translate(-100%, 0%);
  }
  100% {
    opacity: 1;
    transform: translate(0%, 0%);
  }
}

@keyframes slideMenuOut {
  0% {
    transform: translate(0%, 0%);
    z-index: 100;
    opacity: 1;
  }
  99% {
    transform: translate(-100%, 0%);
    z-index: 100;
    opacity: 0;
  }
  100% {
    transform: translate(-100%, 0%);
    z-index: -1;
    opacity: 0;
  }
}

@keyframes slideLegendIn {
  0% {
    opacity: 1;
    transform: translate(100%, 0%);
  }
  100% {
    opacity: 1;
    transform: translate(0%, 0%);
  }
}

@keyframes slideLegendOut {
  0% {
    transform: translate(0%, 0%);
    z-index: 100;
  }
  99% {
    transform: translate(100%, 0%);
    z-index: 100;
  }
  100% {
    transform: translate(100%, 0%);
    z-index: -1;
  }
}

/* fix iOS bug not displaying 100vh correctly */
/* iPad Pro */
@media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) and (orientation : landscape) {
	.fullheight {
		height: 1024px;
	}
}
@media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) and (orientation : portrait) {
	.fullheight {
		height: 1366px;
	}
}

/* iPad */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
	.fullheight {
		height: 680px;
	}
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
	.fullheight {
    height: 870px;
	}
}


/* iPhone 4 */
/* @media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
	.fullheight {
		height: 320px;
	}
} */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
	.fullheight {
		height: 480px;
	}
}

/* iPhone5 */
/* @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {
	.fullheight {
		height: 320px;
	}
} */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
	.fullheight {
		height: 384px;
	}
}

/* iphone 6, 6s, 7, 8 */
/* @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {
	.fullheight {
		height: 375px;
	}
} */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
	.fullheight {
		height: 480px;
	}
}

/* iPhone 6/7/8 plus */
/* @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {
	.fullheight {
		height: 414px;
	}
} */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
	.fullheight {
		height: 544px;
	}
}

/* iphone X */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  .fullheight {
		height: 544px;
	}
}

/* iphone XS */
@media only screen and (min-device-width : 375px) and (max-device-height : 812px) and (orientation : portrait) and (-webkit-device-pixel-ratio : 3) {
  .fullheight {
		height: 544px;
	}
}

/* iphone XR */
@media only screen and (min-device-width : 414px) and (max-device-height : 896px) and (orientation : portrait) and (-webkit-device-pixel-ratio : 2) { 
  .fullheight {
		height: 630px;
	}
}

/* iphone XS Max */
 @media only screen and (min-device-width : 414px) and (max-device-height : 896px) and (orientation : portrait) and (-webkit-device-pixel-ratio : 3) { 
  .fullheight {
		height: 630px;
	}
}

.pulse {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ios-fix {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  perspective: 1000;
  -webkit-perspective: 1000;
}

.highlights {
  font-weight: bold;
  font-style: italic;
}

.menu-selected:after {
  position: absolute;
  content: "";
  height: 2px;
  background-color: #FFF;
  width: 60%;
  margin-left: 0px;
  left: 0;
  top: 100%;
} 

.transition-in {
  transform: translate(100px, 0);
  animation: transition-in 2s forwards;
}

@keyframes transition-in {
  0% {
    transform: translate(100px, 0);
    opacity: 0;
  }
  100% {
    transform: translate(0, 0);
    opacity: 1;
  }
}

.link, .link-white, .link2, .link-lang {
  position: relative;
  cursor: pointer;
}

.active {
  transition: 0.5s opacity;
}

.link:hover:after, .link.active:after, .link2:hover:after, .link-lang:hover:after {
  opacity: 1;
  transition: 0.5s opacity;
}

.link-white:hover:after, .link-white.active:after {
  opacity: 1;
  transition: 0.5s opacity;
}

.link:after, .active:after, .link-white:after, .link2:after {
  position: absolute;
  content: "";
  height: 3px;
  background-color: #43449a;
  width: 100%;
  margin-left: 0px;
  left: 0;
  top: 20px;
  opacity: 0;
}

.link-lang:after {
  position: absolute;
  content: "";
  height: 3px;
  background-color: #43449a;
  width: 58px;
  margin-left: 0px;
  left: 20px;
  top: 18px;
  opacity: 0;
}

.link2:after {
  background-color: #d88383;
}

.link-white:after {
  background-color: #ffffff;
  top: 32px;
  width: 20%;
}

@media only screen and (min-width: 900px) {
  .link-white:after {
    background-color: #ffffff;
    top: 60px;
    width: 15%;
  }
}

.explore-icon {
  width: 50px;
  height: 50px;
  position: relative;
  margin-top: 15px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.explore-icon-circle {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  animation: explore-anim 2s infinite;
  border: 1px solid rgba(67,68,154, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
}

.explore-text { 
  font-weight: 700;
  text-align: left;
  font-size: 18px; 
  color: #43449a; 
  letter-spacing: 1px;
  cursor: pointer;
}

@keyframes explore-anim {
  0% {
    border-color: rgba(67,68,154, 1);
    width: 35px;
    height: 35px;
  }
  100% {
    border-color: rgba(67,68,154, 0);
    width: 50px;
    height: 50px;
  }
}

.icon-circle {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  /* animation: icon-anim 2s infinite; */
  border: 1px solid rgba(67,68,154, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
}

.icon-circle-arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  opacity: 1;
  transition: 0.5s opacity;
}

.icon-circle-arrow:hover {
  opacity: 0.5;
  transition: 0.5s opacity;
}

@keyframes icon-anim {
  0% {
    border-color: rgba(67,68,154, 1);
  }
  100% {
    border-color: rgba(67,68,154, 0);
  }
}

.how-to-read {
  width: 100%;
  position: fixed;
  height: calc(100vh - 50px);
}

.how-to-read-image {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: calc(100% - 40px);
  width: 90%;
  margin-left: 5%;
}

.SocialMediaShareButton--facebook, .SocialMediaShareButton--twitter {
  border: 1px solid #43449a;
  border-radius: 100%;
  cursor: pointer;
}