
#blupetals .cls-1{ stroke:#6ea4a8;stroke-miterlimit:10;stroke-width:0.3px; }
#blupetals .cls-2{fill:#607cbd;}

.bluepetal {
  transition: 0.2s opacity;
}

.grid0 {
  fill: #ededed;
  stroke: #ffffff;
  stroke-miterlimit: 10;
}
.grid1 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-miterlimit: 10;
}
.grid2 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke-dasharray: 1.995, 3.9901;
}
.grid3 {
  fill: #3f4696;
  transition: .4s all;
}

.grid4 {
  fill: none;
  stroke: #3f4696;
  stroke-miterlimit: 10;
}

@media only screen and (min-width: 768px) {
  .grid5 {
    cursor: pointer;
  }
}

.line {
  fill: none;
  stroke: #617cbb;
  stroke-width: 1;
  stroke-miterlimit: 10;
  stroke-dasharray: 0.6, 3;
}

$rotation-base-angle: 30deg;
// $start-rotation: $rotation-base-angle * 11;
$start-rotation: -90deg;
$rotation-time: 2s;
$rotation-ease: ease-out;
$first-row-delay-time: 0s;

@for $i from 0 through 11 {
  $rotation-angle: ($rotation-base-angle * $i) - 90deg;

  #bluepetal-#{$i} {
    transform: rotate($rotation-angle);
    transform-box: border-box;
    transform-origin: 0px 24px;
    // outline: 1px solid black;
    animation: bluePetalRotation#{$i}
      $rotation-time
      $first-row-delay-time
      forwards;
    transition-timing-function: $rotation-ease;
  }

  @keyframes bluePetalRotation#{$i} {
    0% {
      transform: rotate($start-rotation);
    }
    100% {
      transform: rotate(-90deg + ($rotation-base-angle * $i));
    }
  }
}

@for $i from 0 through 11 {
  $delay: $i * 0.05s;

  .blupetal-#{$i} {
    // animation: scaleAnimation 0.8s $delay forwards;
    // will-change: transform;
  }
}

@keyframes scaleAnimation {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.blue_no_data_0{
  fill:url(#blue_petal_empty);
  stroke:#78A2A7;
  stroke-width:0.3;
  stroke-miterlimit:10;
}
.blue_no_data_1{
  fill:#4D6673;
}
