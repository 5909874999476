.langEl {
  font-weight: bold;
  font-size: 14px;
  color: #43449a;
  font-family: 'Raleway', sans-serif;
  width: 40px;
  text-align: center;
  cursor: pointer;
}

.langEl-selected {
  width: 20px;
  height: 3px;
  background-color: #43449a;
  position: absolute;
  transition: 0.5s all;
  top: 18px;
  margin-left: 10px;
}

@media only screen and (min-width: 900px) {
  .langEl {
    width: 100px;
  }
  .langEl-selected {
    width: 60px;
    margin-left: 20px;
  }
}

.yearsSelectorContainer {
  width: 100%;
  display: flex; 
  justify-content: center;
}

.yearsSelectorContainerWrapper {
  width: 275px;
  position: relative;
  display: flex;
}

.yearEl {
  width: 25px;
  height: 25px;
  display: flex;
  justify-items: center;
  align-items: center;
  position: relative;
}

.yearCircle {
  width: 12px;
  height: 9px;
  background-color: #43449a;
  border-radius: 100%;
  position: relative;
  opacity: 1;
  transition: 0.5s opacity;
}

.yearCircle:hover {
  opacity: 0.5;
  transition: 0.5s opacity;
}

.yearTouchArea {
  width: 25px;
  height: 25px;
  background-color: transparent;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0px;
}

.yearEl-selected {
  width: 21px;
  height: 21px;
  border-radius: 21px;
  border: 1px solid #43449a;
  background-color: transparent;
  position: absolute;
  transition: 0.5s all;
  top: -4px;
}

.yearLine {
  width: 50%;
  height: 1px;
  background-color: #43449a;
  opacity: 0.7;
}

.yearVal {
  position: absolute;
  top: 24px;
  font-size: 13px;
  left: 0;
}

@media only screen and (min-width: 900px) {
  .yearsSelectorScrollContainer {
    position: absolute;
    right: 0px;
    height: 80%;
    width: 12.5%;
    top: 10%;
    z-index: 99;
    display: flex;
    justify-content: center;
  }

  .yearsSelectorContainer {
    position: absolute;
    right: 0px;
    width: 100%;
    top: -10%;
    z-index: 99;
    overflow: scroll;
  }

  .yearsSelectorContainerWrapper {
    width: 25px;
    flex-direction: column;
    position: fixed;
    top: 50%;
    margin-top: -170px;
  }

  .yearEl {
    height: 35px;
    flex-direction: column;
    cursor: pointer;
  }

  .yearLine {
    height: 50.2%;
    width: 1px;
    background-color: #43449a;
  }

  .yearTouchArea {
    top: 0;
  }

  .yearCircle {
    width: 9px;
    height: 12px;
    border-radius: 12px;
  }

  .yearVal {
    top: 4px;
    left: 30px;
  }
}

.topicSelector {
  height: 50px;
  width: 70%;
  border: 2px solid #43449a;
  border-radius: 15px;
  background-color: rgba($color: #fff, $alpha: 0.3);
  overflow: hidden;
}

.topicSelectorEl {
  font-weight: bold;
  font-size: 14px;
  color: #43449a;
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 900;
  text-align: center;
}

.react-swipeable-view-container {
  width: 100%;
  height: 100%;
}

.navigationBarContainer {
  position: absolute;
  left: 0px;
  height: 80%;
  width: 12.5%;
  top: 10%;
  z-index: 99;
  display: flex;
  justify-content: center;
}

.navigationBar {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  top: 50%;
  margin-top: -200px;
  width: 100px;
}