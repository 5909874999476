.landing {
  background-image: -webkit-linear-gradient(#e1eaef, #e4f3ec);
  background-image: linear-gradient(#e1eaef, #e4f3ec);
  height: 100vh; }

.landing-content {
  height: 70%;
  margin-top: 15%; }
  .landing-content .author {
    font-size: calc(13px + (26 - 13) * ((100vw - 300px) / (1600 - 300)));
    font-family: 'Raleway';
    color: white; }
  .landing-content .title {
    font-size: calc(55px + (110 - 55) * ((100vw - 300px) / (1600 - 300)));
    color: white; }
  .landing-content .subtitle {
    font-size: calc(16px + (32 - 16) * ((100vw - 300px) / (1600 - 300)));
    color: white; }
  .landing-content .call-to-action {
    font-family: 'Raleway';
    color: white;
    font-size: calc(9px + (18 - 9) * ((100vw - 300px) / (1600 - 300))); }
  .landing-content .lighter-weight {
    font-weight: lighter;
    margin: 0 5px;
    color: white; }

@media only screen and (min-width: 768px) {
  .landing-content {
    height: 60%;
    max-width: 75%;
    position: absolute;
    top: -50px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto; } }

.landing-icon-container {
  width: 120px;
  height: 120px;
  border: 2px solid white;
  border-radius: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-animation: pulse-border 2s infinite;
          animation: pulse-border 2s infinite;
  cursor: pointer; }

@media only screen and (min-width: 768px) {
  width: 160px;
  height: 160px; }

@-webkit-keyframes pulse-border {
  0% {
    border-color: white; }
  50% {
    border-color: rgba(255, 255, 255, 0); }
  100% {
    border-color: white; } }

@keyframes pulse-border {
  0% {
    border-color: white; }
  50% {
    border-color: rgba(255, 255, 255, 0); }
  100% {
    border-color: white; } }

.landing-icon {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-animation: rotation 240s infinite;
          animation: rotation 240s infinite; }

@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.not-scrolling {
  background-position: 0 0%;
  -webkit-transition: 2s background-position;
  transition: 2s background-position;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  will-change: background-position; }

.scrolling {
  background-position: 0 100%;
  -webkit-transition: 2s background-position;
  transition: 2s background-position;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  will-change: background-position; }

.landing-content.up {
  -webkit-animation: gravity-up 2s forwards;
          animation: gravity-up 2s forwards;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
          transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

@-webkit-keyframes gravity1 {
  0% {
    -webkit-transform: translate(0px, -500px);
            transform: translate(0px, -500px); }
  98% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); }
  100% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); } }

@keyframes gravity1 {
  0% {
    -webkit-transform: translate(0px, -500px);
            transform: translate(0px, -500px); }
  98% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); }
  100% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); } }

@-webkit-keyframes gravity2 {
  0% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); }
  98% {
    -webkit-transform: translate(0px, -100px);
            transform: translate(0px, -100px); }
  100% {
    -webkit-transform: translate(1000px, -100px);
            transform: translate(1000px, -100px); } }

@keyframes gravity2 {
  0% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); }
  98% {
    -webkit-transform: translate(0px, -100px);
            transform: translate(0px, -100px); }
  100% {
    -webkit-transform: translate(1000px, -100px);
            transform: translate(1000px, -100px); } }

@-webkit-keyframes gravity3 {
  0% {
    -webkit-transform: translate(0px, -500px);
            transform: translate(0px, -500px); }
  98% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); }
  100% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); } }

@keyframes gravity3 {
  0% {
    -webkit-transform: translate(0px, -500px);
            transform: translate(0px, -500px); }
  98% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); }
  100% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); } }

@-webkit-keyframes gravity-up {
  0% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); }
  100% {
    -webkit-transform: translate(0px, -400px);
            transform: translate(0px, -400px); } }

@keyframes gravity-up {
  0% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); }
  100% {
    -webkit-transform: translate(0px, -400px);
            transform: translate(0px, -400px); } }

.fadeOut {
  opacity: 0;
  -webkit-transition: 1s opacity;
  transition: 1s opacity;
  will-change: opacity; }

.topic {
  width: 100%;
  text-align: center;
  font-size: 35px;
  -webkit-transition: 1s all;
  transition: 1s all; }

.value {
  width: 100%;
  text-align: center;
  font-size: 15px;
  opacity: 0.6; }

.petal_no_data_0 {
  fill: #384F5B; }

.petal_no_data_1 {
  opacity: 0.7; }

.st0 {
  fill: #c97d75; }

.st1 {
  opacity: 0.7; }

.shadow {
  opacity: 0.7;
  fill: url(#SVGID_1_); }

#petal-1 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationPositive4 1s 0.3s forwards;
          animation: rotationPositive4 1s 0.3s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-2 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationPositive3 1s 0.3s forwards;
          animation: rotationPositive3 1s 0.3s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-3 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationPositive2 1s 0.3s forwards;
          animation: rotationPositive2 1s 0.3s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-4 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationPositive1 1s 0.3s forwards;
          animation: rotationPositive1 1s 0.3s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-5 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotation0 1s 0.3s forwards;
          animation: rotation0 1s 0.3s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-6 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationNegative1 1s 0.3s forwards;
          animation: rotationNegative1 1s 0.3s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-7 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationNegative2 1s 0.3s forwards;
          animation: rotationNegative2 1s 0.3s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-8 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationNegative3 1s 0.3s forwards;
          animation: rotationNegative3 1s 0.3s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-9 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationNegative4 1s 0.3s forwards;
          animation: rotationNegative4 1s 0.3s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-10 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationPositive5 1s 0.8s forwards;
          animation: rotationPositive5 1s 0.8s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-11 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationPositive4 1s 0.8s forwards;
          animation: rotationPositive4 1s 0.8s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-12 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationPositive3 1s 0.8s forwards;
          animation: rotationPositive3 1s 0.8s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-13 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationPositive2 1s 0.8s forwards;
          animation: rotationPositive2 1s 0.8s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-14 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationPositive1 1s 0.8s forwards;
          animation: rotationPositive1 1s 0.8s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-15 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotation0 1s 0.8s forwards;
          animation: rotation0 1s 0.8s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-16 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationNegative1 1s 0.8s forwards;
          animation: rotationNegative1 1s 0.8s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-17 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationNegative2 1s 0.8s forwards;
          animation: rotationNegative2 1s 0.8s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-18 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationNegative3 1s 0.8s forwards;
          animation: rotationNegative3 1s 0.8s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-19 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationNegative4 1s 0.8s forwards;
          animation: rotationNegative4 1s 0.8s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-20 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationNegative5 1s 0.8s forwards;
          animation: rotationNegative5 1s 0.8s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-21 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationPositive6 1s 1.3s forwards;
          animation: rotationPositive6 1s 1.3s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-22 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationPositive5 1s 1.3s forwards;
          animation: rotationPositive5 1s 1.3s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-23 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationPositive4 1s 1.3s forwards;
          animation: rotationPositive4 1s 1.3s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-24 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationPositive3 1s 1.3s forwards;
          animation: rotationPositive3 1s 1.3s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-25 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationPositive2 1s 1.3s forwards;
          animation: rotationPositive2 1s 1.3s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-26 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationPositive1 1s 1.3s forwards;
          animation: rotationPositive1 1s 1.3s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-27 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotation0 1s 1.3s forwards;
          animation: rotation0 1s 1.3s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-28 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationNegative1 1s 1.3s forwards;
          animation: rotationNegative1 1s 1.3s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-29 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationNegative2 1s 1.3s forwards;
          animation: rotationNegative2 1s 1.3s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-30 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationNegative3 1s 1.3s forwards;
          animation: rotationNegative3 1s 1.3s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-31 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationNegative4 1s 1.3s forwards;
          animation: rotationNegative4 1s 1.3s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-32 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationNegative5 1s 1.3s forwards;
          animation: rotationNegative5 1s 1.3s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

#petal-33 {
  -webkit-transform: rotate(81.6deg);
          transform: rotate(81.6deg);
  -webkit-animation: rotationNegative6 1s 1.3s forwards;
          animation: rotationNegative6 1s 1.3s forwards;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  -webkit-transform-origin: 45px 0;
          transform-origin: 45px 0;
  will-change: transform; }

@-webkit-keyframes rotationNegative6 {
  0% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); }
  100% {
    -webkit-transform: rotate(-81.6deg);
            transform: rotate(-81.6deg); } }

@keyframes rotationNegative6 {
  0% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); }
  100% {
    -webkit-transform: rotate(-81.6deg);
            transform: rotate(-81.6deg); } }

@-webkit-keyframes rotationNegative5 {
  0% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); }
  100% {
    -webkit-transform: rotate(-68deg);
            transform: rotate(-68deg); } }

@keyframes rotationNegative5 {
  0% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); }
  100% {
    -webkit-transform: rotate(-68deg);
            transform: rotate(-68deg); } }

@-webkit-keyframes rotationNegative4 {
  0% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); }
  100% {
    -webkit-transform: rotate(-54.4deg);
            transform: rotate(-54.4deg); } }

@keyframes rotationNegative4 {
  0% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); }
  100% {
    -webkit-transform: rotate(-54.4deg);
            transform: rotate(-54.4deg); } }

@-webkit-keyframes rotationNegative3 {
  0% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); }
  100% {
    -webkit-transform: rotate(-40.8deg);
            transform: rotate(-40.8deg); } }

@keyframes rotationNegative3 {
  0% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); }
  100% {
    -webkit-transform: rotate(-40.8deg);
            transform: rotate(-40.8deg); } }

@-webkit-keyframes rotationNegative2 {
  0% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); }
  100% {
    -webkit-transform: rotate(-27.2deg);
            transform: rotate(-27.2deg); } }

@keyframes rotationNegative2 {
  0% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); }
  100% {
    -webkit-transform: rotate(-27.2deg);
            transform: rotate(-27.2deg); } }

@-webkit-keyframes rotationNegative1 {
  0% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); }
  100% {
    -webkit-transform: rotate(-13.6deg);
            transform: rotate(-13.6deg); } }

@keyframes rotationNegative1 {
  0% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); }
  100% {
    -webkit-transform: rotate(-13.6deg);
            transform: rotate(-13.6deg); } }

@-webkit-keyframes rotation0 {
  0% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes rotation0 {
  0% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@-webkit-keyframes rotationPositive1 {
  0% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); }
  100% {
    -webkit-transform: rotate(13.6deg);
            transform: rotate(13.6deg); } }

@keyframes rotationPositive1 {
  0% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); }
  100% {
    -webkit-transform: rotate(13.6deg);
            transform: rotate(13.6deg); } }

@-webkit-keyframes rotationPositive2 {
  0% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); }
  100% {
    -webkit-transform: rotate(27.2deg);
            transform: rotate(27.2deg); } }

@keyframes rotationPositive2 {
  0% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); }
  100% {
    -webkit-transform: rotate(27.2deg);
            transform: rotate(27.2deg); } }

@-webkit-keyframes rotationPositive3 {
  0% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); }
  100% {
    -webkit-transform: rotate(40.8deg);
            transform: rotate(40.8deg); } }

@keyframes rotationPositive3 {
  0% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); }
  100% {
    -webkit-transform: rotate(40.8deg);
            transform: rotate(40.8deg); } }

@-webkit-keyframes rotationPositive4 {
  0% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); }
  100% {
    -webkit-transform: rotate(54.4deg);
            transform: rotate(54.4deg); } }

@keyframes rotationPositive4 {
  0% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); }
  100% {
    -webkit-transform: rotate(54.4deg);
            transform: rotate(54.4deg); } }

@-webkit-keyframes rotationPositive5 {
  0% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); }
  100% {
    -webkit-transform: rotate(68deg);
            transform: rotate(68deg); } }

@keyframes rotationPositive5 {
  0% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); }
  100% {
    -webkit-transform: rotate(68deg);
            transform: rotate(68deg); } }

@-webkit-keyframes rotationPositive6 {
  0% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); }
  100% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); } }

@keyframes rotationPositive6 {
  0% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); }
  100% {
    -webkit-transform: rotate(81.6deg);
            transform: rotate(81.6deg); } }

.petal-row-1 {
  -webkit-transform: translate(0px, -500px);
          transform: translate(0px, -500px);
  -webkit-animation: gravity1 0.5s forwards;
          animation: gravity1 0.5s forwards;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
          transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.petal-row-2 {
  -webkit-transform: translate(0px, -500px);
          transform: translate(0px, -500px);
  -webkit-animation: gravity2 1s forwards;
          animation: gravity2 1s forwards;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
          transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.petal-row-3 {
  -webkit-transform: translate(0px, -500px);
          transform: translate(0px, -500px);
  -webkit-animation: gravity3 1.5s forwards;
          animation: gravity3 1.5s forwards;
  -webkit-transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
          transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

.petal-0.show {
  -webkit-animation: movement-0 forwards;
          animation: movement-0 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-0 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-0 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-1.show {
  -webkit-animation: movement-1 forwards;
          animation: movement-1 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-1 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-1 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-2.show {
  -webkit-animation: movement-2 forwards;
          animation: movement-2 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-2 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(1deg);
            transform: rotate(1deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-2 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(1deg);
            transform: rotate(1deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-3.show {
  -webkit-animation: movement-3 forwards;
          animation: movement-3 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-3 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-3 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-4.show {
  -webkit-animation: movement-4 forwards;
          animation: movement-4 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-4 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(6deg);
            transform: rotate(6deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-4 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(6deg);
            transform: rotate(6deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-5.show {
  -webkit-animation: movement-5 forwards;
          animation: movement-5 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-5 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-5 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-6.show {
  -webkit-animation: movement-6 forwards;
          animation: movement-6 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-6 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-6 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-7.show {
  -webkit-animation: movement-7 forwards;
          animation: movement-7 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-7 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-7 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-8.show {
  -webkit-animation: movement-8 forwards;
          animation: movement-8 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-8 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-8 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-9.show {
  -webkit-animation: movement-9 forwards;
          animation: movement-9 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-9 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-9 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-10.show {
  -webkit-animation: movement-10 forwards;
          animation: movement-10 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-10 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(1deg);
            transform: rotate(1deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-10 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(1deg);
            transform: rotate(1deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-11.show {
  -webkit-animation: movement-11 forwards;
          animation: movement-11 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-11 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-11 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-12.show {
  -webkit-animation: movement-12 forwards;
          animation: movement-12 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-12 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-12 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-13.show {
  -webkit-animation: movement-13 forwards;
          animation: movement-13 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-13 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-13 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-14.show {
  -webkit-animation: movement-14 forwards;
          animation: movement-14 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-14 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-14 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-15.show {
  -webkit-animation: movement-15 forwards;
          animation: movement-15 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-15 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-15 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-16.show {
  -webkit-animation: movement-16 forwards;
          animation: movement-16 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-16 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-16 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-17.show {
  -webkit-animation: movement-17 forwards;
          animation: movement-17 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-17 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-17 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-18.show {
  -webkit-animation: movement-18 forwards;
          animation: movement-18 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-18 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(1deg);
            transform: rotate(1deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-18 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(1deg);
            transform: rotate(1deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-19.show {
  -webkit-animation: movement-19 forwards;
          animation: movement-19 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-19 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(6deg);
            transform: rotate(6deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-19 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(6deg);
            transform: rotate(6deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-20.show {
  -webkit-animation: movement-20 forwards;
          animation: movement-20 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-20 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(1deg);
            transform: rotate(1deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-20 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(1deg);
            transform: rotate(1deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-21.show {
  -webkit-animation: movement-21 forwards;
          animation: movement-21 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-21 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-21 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-22.show {
  -webkit-animation: movement-22 forwards;
          animation: movement-22 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-22 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-22 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-23.show {
  -webkit-animation: movement-23 forwards;
          animation: movement-23 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-23 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(1deg);
            transform: rotate(1deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-23 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(1deg);
            transform: rotate(1deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-24.show {
  -webkit-animation: movement-24 forwards;
          animation: movement-24 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-24 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-24 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-25.show {
  -webkit-animation: movement-25 forwards;
          animation: movement-25 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-25 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-25 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(5deg);
            transform: rotate(5deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-26.show {
  -webkit-animation: movement-26 forwards;
          animation: movement-26 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-26 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-26 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-27.show {
  -webkit-animation: movement-27 forwards;
          animation: movement-27 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-27 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(1deg);
            transform: rotate(1deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-27 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(1deg);
            transform: rotate(1deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-28.show {
  -webkit-animation: movement-28 forwards;
          animation: movement-28 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-28 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(6deg);
            transform: rotate(6deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-28 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(6deg);
            transform: rotate(6deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-29.show {
  -webkit-animation: movement-29 forwards;
          animation: movement-29 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-29 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(6deg);
            transform: rotate(6deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-29 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(6deg);
            transform: rotate(6deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-30.show {
  -webkit-animation: movement-30 forwards;
          animation: movement-30 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-30 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(6deg);
            transform: rotate(6deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-30 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(6deg);
            transform: rotate(6deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-31.show {
  -webkit-animation: movement-31 forwards;
          animation: movement-31 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-31 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-31 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(4deg);
            transform: rotate(4deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-32.show {
  -webkit-animation: movement-32 forwards;
          animation: movement-32 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-32 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-32 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(3deg);
            transform: rotate(3deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

.petal-33.show {
  -webkit-animation: movement-33 forwards;
          animation: movement-33 forwards;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes movement-33 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(6deg);
            transform: rotate(6deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes movement-33 {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  25% {
    -webkit-transform: rotate(-5deg);
            transform: rotate(-5deg); }
  75% {
    -webkit-transform: rotate(6deg);
            transform: rotate(6deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@-webkit-keyframes gravity1 {
  0% {
    -webkit-transform: translate(0px, -500px);
            transform: translate(0px, -500px); }
  98% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); }
  100% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); } }

@keyframes gravity1 {
  0% {
    -webkit-transform: translate(0px, -500px);
            transform: translate(0px, -500px); }
  98% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); }
  100% {
    -webkit-transform: translate(0px, 0px);
            transform: translate(0px, 0px); } }

@-webkit-keyframes gravity2 {
  0% {
    -webkit-transform: translate(0px, -500px);
            transform: translate(0px, -500px); }
  98% {
    -webkit-transform: translate(0px, 65px);
            transform: translate(0px, 65px); }
  100% {
    -webkit-transform: translate(0px, 65px);
            transform: translate(0px, 65px); } }

@keyframes gravity2 {
  0% {
    -webkit-transform: translate(0px, -500px);
            transform: translate(0px, -500px); }
  98% {
    -webkit-transform: translate(0px, 65px);
            transform: translate(0px, 65px); }
  100% {
    -webkit-transform: translate(0px, 65px);
            transform: translate(0px, 65px); } }

@-webkit-keyframes gravity3 {
  0% {
    -webkit-transform: translate(0px, -500px);
            transform: translate(0px, -500px); }
  98% {
    -webkit-transform: translate(0px, 126px);
            transform: translate(0px, 126px); }
  100% {
    -webkit-transform: translate(0px, 126px);
            transform: translate(0px, 126px); } }

@keyframes gravity3 {
  0% {
    -webkit-transform: translate(0px, -500px);
            transform: translate(0px, -500px); }
  98% {
    -webkit-transform: translate(0px, 126px);
            transform: translate(0px, 126px); }
  100% {
    -webkit-transform: translate(0px, 126px);
            transform: translate(0px, 126px); } }

.langEl {
  font-weight: bold;
  font-size: 14px;
  color: #43449a;
  font-family: 'Raleway', sans-serif;
  width: 40px;
  text-align: center;
  cursor: pointer; }

.langEl-selected {
  width: 20px;
  height: 3px;
  background-color: #43449a;
  position: absolute;
  -webkit-transition: 0.5s all;
  transition: 0.5s all;
  top: 18px;
  margin-left: 10px; }

@media only screen and (min-width: 900px) {
  .langEl {
    width: 100px; }
  .langEl-selected {
    width: 60px;
    margin-left: 20px; } }

.yearsSelectorContainer {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }

.yearsSelectorContainerWrapper {
  width: 275px;
  position: relative;
  display: -webkit-flex;
  display: flex; }

.yearEl {
  width: 25px;
  height: 25px;
  display: -webkit-flex;
  display: flex;
  justify-items: center;
  -webkit-align-items: center;
          align-items: center;
  position: relative; }

.yearCircle {
  width: 12px;
  height: 9px;
  background-color: #43449a;
  border-radius: 100%;
  position: relative;
  opacity: 1;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.yearCircle:hover {
  opacity: 0.5;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.yearTouchArea {
  width: 25px;
  height: 25px;
  background-color: transparent;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0px; }

.yearEl-selected {
  width: 21px;
  height: 21px;
  border-radius: 21px;
  border: 1px solid #43449a;
  background-color: transparent;
  position: absolute;
  -webkit-transition: 0.5s all;
  transition: 0.5s all;
  top: -4px; }

.yearLine {
  width: 50%;
  height: 1px;
  background-color: #43449a;
  opacity: 0.7; }

.yearVal {
  position: absolute;
  top: 24px;
  font-size: 13px;
  left: 0; }

@media only screen and (min-width: 900px) {
  .yearsSelectorScrollContainer {
    position: absolute;
    right: 0px;
    height: 80%;
    width: 12.5%;
    top: 10%;
    z-index: 99;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center; }
  .yearsSelectorContainer {
    position: absolute;
    right: 0px;
    width: 100%;
    top: -10%;
    z-index: 99;
    overflow: scroll; }
  .yearsSelectorContainerWrapper {
    width: 25px;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: fixed;
    top: 50%;
    margin-top: -170px; }
  .yearEl {
    height: 35px;
    -webkit-flex-direction: column;
            flex-direction: column;
    cursor: pointer; }
  .yearLine {
    height: 50.2%;
    width: 1px;
    background-color: #43449a; }
  .yearTouchArea {
    top: 0; }
  .yearCircle {
    width: 9px;
    height: 12px;
    border-radius: 12px; }
  .yearVal {
    top: 4px;
    left: 30px; } }

.topicSelector {
  height: 50px;
  width: 70%;
  border: 2px solid #43449a;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.3);
  overflow: hidden; }

.topicSelectorEl {
  font-weight: bold;
  font-size: 14px;
  color: #43449a;
  font-family: 'Raleway', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  width: 100%;
  height: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 900;
  text-align: center; }

.react-swipeable-view-container {
  width: 100%;
  height: 100%; }

.navigationBarContainer {
  position: absolute;
  left: 0px;
  height: 80%;
  width: 12.5%;
  top: 10%;
  z-index: 99;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }

.navigationBar {
  height: 400px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: center;
          align-items: center;
  position: absolute;
  top: 50%;
  margin-top: -200px;
  width: 100px; }

#blupetals .cls-1 {
  stroke: #6ea4a8;
  stroke-miterlimit: 10;
  stroke-width: 0.3px; }

#blupetals .cls-2 {
  fill: #607cbd; }

.bluepetal {
  -webkit-transition: 0.2s opacity;
  transition: 0.2s opacity; }

.grid0 {
  fill: #ededed;
  stroke: #ffffff;
  stroke-miterlimit: 10; }

.grid1 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-miterlimit: 10; }

.grid2 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke-dasharray: 1.995, 3.9901; }

.grid3 {
  fill: #3f4696;
  -webkit-transition: .4s all;
  transition: .4s all; }

.grid4 {
  fill: none;
  stroke: #3f4696;
  stroke-miterlimit: 10; }

@media only screen and (min-width: 768px) {
  .grid5 {
    cursor: pointer; } }

.line {
  fill: none;
  stroke: #617cbb;
  stroke-width: 1;
  stroke-miterlimit: 10;
  stroke-dasharray: 0.6, 3; }

#bluepetal-0 {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  transform-box: border-box;
  -webkit-transform-origin: 0px 24px;
          transform-origin: 0px 24px;
  -webkit-animation: bluePetalRotation0 2s 0s forwards;
          animation: bluePetalRotation0 2s 0s forwards;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out; }

@-webkit-keyframes bluePetalRotation0 {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); } }

@keyframes bluePetalRotation0 {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); } }

#bluepetal-1 {
  -webkit-transform: rotate(-60deg);
          transform: rotate(-60deg);
  transform-box: border-box;
  -webkit-transform-origin: 0px 24px;
          transform-origin: 0px 24px;
  -webkit-animation: bluePetalRotation1 2s 0s forwards;
          animation: bluePetalRotation1 2s 0s forwards;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out; }

@-webkit-keyframes bluePetalRotation1 {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  100% {
    -webkit-transform: rotate(-60deg);
            transform: rotate(-60deg); } }

@keyframes bluePetalRotation1 {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  100% {
    -webkit-transform: rotate(-60deg);
            transform: rotate(-60deg); } }

#bluepetal-2 {
  -webkit-transform: rotate(-30deg);
          transform: rotate(-30deg);
  transform-box: border-box;
  -webkit-transform-origin: 0px 24px;
          transform-origin: 0px 24px;
  -webkit-animation: bluePetalRotation2 2s 0s forwards;
          animation: bluePetalRotation2 2s 0s forwards;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out; }

@-webkit-keyframes bluePetalRotation2 {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  100% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg); } }

@keyframes bluePetalRotation2 {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  100% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg); } }

#bluepetal-3 {
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  transform-box: border-box;
  -webkit-transform-origin: 0px 24px;
          transform-origin: 0px 24px;
  -webkit-animation: bluePetalRotation3 2s 0s forwards;
          animation: bluePetalRotation3 2s 0s forwards;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out; }

@-webkit-keyframes bluePetalRotation3 {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

@keyframes bluePetalRotation3 {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }

#bluepetal-4 {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
  transform-box: border-box;
  -webkit-transform-origin: 0px 24px;
          transform-origin: 0px 24px;
  -webkit-animation: bluePetalRotation4 2s 0s forwards;
          animation: bluePetalRotation4 2s 0s forwards;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out; }

@-webkit-keyframes bluePetalRotation4 {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  100% {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg); } }

@keyframes bluePetalRotation4 {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  100% {
    -webkit-transform: rotate(30deg);
            transform: rotate(30deg); } }

#bluepetal-5 {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
  transform-box: border-box;
  -webkit-transform-origin: 0px 24px;
          transform-origin: 0px 24px;
  -webkit-animation: bluePetalRotation5 2s 0s forwards;
          animation: bluePetalRotation5 2s 0s forwards;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out; }

@-webkit-keyframes bluePetalRotation5 {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  100% {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg); } }

@keyframes bluePetalRotation5 {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  100% {
    -webkit-transform: rotate(60deg);
            transform: rotate(60deg); } }

#bluepetal-6 {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  transform-box: border-box;
  -webkit-transform-origin: 0px 24px;
          transform-origin: 0px 24px;
  -webkit-animation: bluePetalRotation6 2s 0s forwards;
          animation: bluePetalRotation6 2s 0s forwards;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out; }

@-webkit-keyframes bluePetalRotation6 {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); } }

@keyframes bluePetalRotation6 {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  100% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); } }

#bluepetal-7 {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
  transform-box: border-box;
  -webkit-transform-origin: 0px 24px;
          transform-origin: 0px 24px;
  -webkit-animation: bluePetalRotation7 2s 0s forwards;
          animation: bluePetalRotation7 2s 0s forwards;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out; }

@-webkit-keyframes bluePetalRotation7 {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  100% {
    -webkit-transform: rotate(120deg);
            transform: rotate(120deg); } }

@keyframes bluePetalRotation7 {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  100% {
    -webkit-transform: rotate(120deg);
            transform: rotate(120deg); } }

#bluepetal-8 {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
  transform-box: border-box;
  -webkit-transform-origin: 0px 24px;
          transform-origin: 0px 24px;
  -webkit-animation: bluePetalRotation8 2s 0s forwards;
          animation: bluePetalRotation8 2s 0s forwards;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out; }

@-webkit-keyframes bluePetalRotation8 {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  100% {
    -webkit-transform: rotate(150deg);
            transform: rotate(150deg); } }

@keyframes bluePetalRotation8 {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  100% {
    -webkit-transform: rotate(150deg);
            transform: rotate(150deg); } }

#bluepetal-9 {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
  transform-box: border-box;
  -webkit-transform-origin: 0px 24px;
          transform-origin: 0px 24px;
  -webkit-animation: bluePetalRotation9 2s 0s forwards;
          animation: bluePetalRotation9 2s 0s forwards;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out; }

@-webkit-keyframes bluePetalRotation9 {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  100% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); } }

@keyframes bluePetalRotation9 {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  100% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); } }

#bluepetal-10 {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
  transform-box: border-box;
  -webkit-transform-origin: 0px 24px;
          transform-origin: 0px 24px;
  -webkit-animation: bluePetalRotation10 2s 0s forwards;
          animation: bluePetalRotation10 2s 0s forwards;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out; }

@-webkit-keyframes bluePetalRotation10 {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  100% {
    -webkit-transform: rotate(210deg);
            transform: rotate(210deg); } }

@keyframes bluePetalRotation10 {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  100% {
    -webkit-transform: rotate(210deg);
            transform: rotate(210deg); } }

#bluepetal-11 {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
  transform-box: border-box;
  -webkit-transform-origin: 0px 24px;
          transform-origin: 0px 24px;
  -webkit-animation: bluePetalRotation11 2s 0s forwards;
          animation: bluePetalRotation11 2s 0s forwards;
  -webkit-transition-timing-function: ease-out;
          transition-timing-function: ease-out; }

@-webkit-keyframes bluePetalRotation11 {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  100% {
    -webkit-transform: rotate(240deg);
            transform: rotate(240deg); } }

@keyframes bluePetalRotation11 {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  100% {
    -webkit-transform: rotate(240deg);
            transform: rotate(240deg); } }

@-webkit-keyframes scaleAnimation {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }

@keyframes scaleAnimation {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }

.blue_no_data_0 {
  fill: url(#blue_petal_empty);
  stroke: #78A2A7;
  stroke-width: 0.3;
  stroke-miterlimit: 10; }

.blue_no_data_1 {
  fill: #4D6673; }

.vx-axis-tick > text {
  font-family: Raleway;
  font-size: 13px;
  font-weight: 300; }

.noteBox {
  height: 100px;
  position: fixed;
  bottom: 50px;
  width: 100%;
  color: white;
  z-index: 150;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: #2d2e7d;
  font-size: 20px; }

@media only screen and (min-width: 900px) {
  .noteBox {
    height: calc(100% - 50px);
    position: fixed;
    bottom: auto;
    top: 50px;
    right: 0;
    left: auto;
    width: 12.5%;
    color: white;
    z-index: 150;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: #43449a;
    font-size: 21px; } }

.noteBox-close {
  position: absolute;
  top: 10px;
  right: 10px; }

#circles image {
  opacity: 0;
  -webkit-transition: .5s opacity;
  transition: .5s opacity; }

.network_1_0 {
  fill: none;
  stroke: #404896;
  stroke-width: 2;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_1_1 {
  fill: none;
  stroke: #404896;
  stroke-width: 1.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_1_2 {
  fill: none;
  stroke: #404896;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity;
  transition: 0.5s opacity; }

.st3 {
  fill: none;
  stroke: #444695;
  stroke-width: 0.5;
  stroke-miterlimit: 10; }

.st4 {
  opacity: 0.5; }

.st5 {
  fill: #444696; }

.st6 {
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
  pointer-events: none; }

.st7 {
  font-size: 13.608px; }

.network_2_0 {
  opacity: 0;
  fill: none;
  stroke: #404896;
  stroke-width: 2.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_2_1 {
  opacity: 0;
  fill: none;
  stroke: #404896;
  stroke-width: 1.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_2_2 {
  opacity: 0;
  fill: none;
  stroke: #404896;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_3_0 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 2;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_3_1 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 1.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_3_2 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 2.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_3_3 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_4_0 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 1.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_4_1 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_4_2 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_5_0 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 4;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_5_1 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_5_2 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 1.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_5_3 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 2.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_5_4 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 4.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_5_5 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_5_6 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 3.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_5_7 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 2;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_6_0 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_6_1 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 4.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_6_2 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_6_3 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 1.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_6_4 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_6_5 {
  opacity: 0.78; }

.network_6_6 {
  fill: none;
  stroke: #404896;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_6_7 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 2;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_6_8 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 2.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_6_9 {
  fill: none;
  stroke: #444695;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_7_0 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_7_1 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 2;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_7_2 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 1.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_7_4 {
  fill: none;
  stroke: #404896;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_7_5 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_7_6 {
  fill: none;
  stroke: #444695;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_8_0 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 2;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_8_1 {
  opacity: 0.78; }

.network_8_2 {
  fill: none;
  stroke: #404896;
  stroke-miterlimit: 10; }

.network_8_3 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 2.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_8_4 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_8_5 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 3.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_8_6 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 1.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_8_7 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_8_8 {
  fill: none;
  stroke: #444695;
  stroke-width: 0.5;
  stroke-miterlimit: 10; }

.network_9_0 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_9_1 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 1.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_9_2 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_9_3 {
  opacity: 0.78; }

.network_9_4 {
  fill: none;
  stroke: #404896;
  stroke-width: 0.5;
  stroke-miterlimit: 10; }

.network_9_5 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 2;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_9_6 {
  fill: none;
  stroke: #444695;
  stroke-width: 0.5;
  stroke-miterlimit: 10; }

.network_10_0 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_10_1 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 4;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_10_2 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 3;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_10_3 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_10_4 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 4.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_10_5 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 2.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_10_6 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 2;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_10_7 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 1.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_11_0 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 3;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_11_1 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 1.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_11_2 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 2;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_11_3 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 0.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_11_4 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_11_5 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 4;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_11_6 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 3.5;
  stroke-miterlimit: 10;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity; }

.network_11_7 {
  opacity: 0.78; }

.network_11_8 {
  fill: none;
  stroke: #404896;
  stroke-width: 0.5;
  stroke-miterlimit: 10; }

.network_11_9 {
  fill: none;
  stroke: #444695;
  stroke-width: 0.5;
  stroke-miterlimit: 10; }

.network_11_10 {
  fill: #454897; }

.network_12_0 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-miterlimit: 10; }

.network_12_1 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 0.5;
  stroke-miterlimit: 10; }

.network_12_2 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 5;
  stroke-miterlimit: 10; }

.network_12_3 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 4.5;
  stroke-miterlimit: 10; }

.network_12_4 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 2;
  stroke-miterlimit: 10; }

.network_12_5 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 1.5;
  stroke-miterlimit: 10; }

.network_12_6 {
  opacity: 0.78;
  fill: none;
  stroke: #404896;
  stroke-width: 3;
  stroke-miterlimit: 10; }

.network_12_7 {
  fill: none;
  stroke: #444695;
  stroke-width: 0.5;
  stroke-miterlimit: 10; }

.network_12_8 {
  fill: #444696; }

#subjects g {
  -webkit-transition: 0.75s opacity;
  transition: 0.75s opacity; }

#header {
  width: 100%;
  position: fixed;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  height: 30px;
  z-index: 99; }

.header-el {
  width: 24%;
  height: 2px;
  background-color: #999999;
  -webkit-transition: 1s all;
  transition: 1s all;
  opacity: 0.3; }

.header-el.selected {
  background-color: #43449a;
  -webkit-transition: 1s all;
  transition: 1s all;
  opacity: 1; }

@media only screen and (min-width: 900px) {
  #header {
    position: fixed;
    top: 60px;
    width: 20px;
    left: 2px;
    height: calc(100% - 80px);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .header-el {
    width: 2px;
    height: 24%;
    background-color: #999999;
    -webkit-transition: 1s all;
    transition: 1s all;
    opacity: 0.3; } }

.footer-el > div {
  color: #43449a;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
  font-size: 14px; }

.footer {
  background-color: white;
  box-shadow: 2px 0px 10px 1px #cecece;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  justify-items: center;
  -webkit-align-items: center;
          align-items: center;
  position: fixed;
  bottom: 0; }

@media only screen and (min-width: 900px) {
  .footer {
    top: 0; } }

.menu .menu-title {
  font-size: 14px; }

.menu .menu-link {
  font-size: 28px; }

@media only screen and (min-width: 767px) {
  .menu .menu-title {
    font-size: 22px; }
  .menu .menu-link {
    font-size: 48px;
    margin-bottom: 30px; }
  .menu .link-white:after {
    background-color: #ffffff;
    top: 56px;
    width: 20%; } }

.about {
  width: 100%;
  height: 100%;
  background-color: #171a56;
  color: white;
  padding: 80px 0px;
  position: fixed;
  z-index: 100; }
  .about.open {
    -webkit-transition: 0.5s -webkit-transform;
    transition: 0.5s -webkit-transform;
    transition: 0.5s transform;
    transition: 0.5s transform, 0.5s -webkit-transform;
    z-index: 1000;
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
    pointer-events: auto; }
  .about.close {
    -webkit-transition: 0.5s -webkit-transform;
    transition: 0.5s -webkit-transform;
    transition: 0.5s transform;
    transition: 0.5s transform, 0.5s -webkit-transform;
    z-index: 1000;
    -webkit-transform: translate(100%, 0%);
            transform: translate(100%, 0%);
    pointer-events: auto; }
  .about .about-wrapper {
    max-width: 75%;
    margin: auto;
    height: inherit;
    overflow-y: auto; }
  .about .close-about {
    position: absolute;
    right: 25px;
    top: 25px; }
  .about .about-title {
    font-size: 32px; }
  .about .about-subtitle {
    font-size: 22px; }
  .about .about-paragraph {
    font-size: 18px; }

@media only screen and (max-width: 900px) {
  .about {
    padding: 0px;
    position: fixed; }
    .about .about-wrapper {
      padding: 30px 20px;
      margin: auto;
      overflow-y: scroll;
      height: inherit;
      max-width: 100%; }
    .about .close-about {
      position: absolute;
      top: 10px;
      right: 10px; }
    .about .about-title {
      font-size: 48px; }
    .about .about-subtitle {
      font-size: 28px; }
    .about .about-paragraph {
      font-size: 24px;
      line-height: 30px; } }

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: -webkit-linear-gradient(#e1eaef, #e4f3ec);
  background-image: linear-gradient(#e1eaef, #e4f3ec);
}

html, body, #root {
  height: 100%;
  width: 100%;
}

@media only screen and (min-width: 900px) {
  body {
    background: white;
  }
  body, #root {
    overflow: hidden;
    height: 100%;
  }
}

* {
  font-family: 'Cormorant Garamond', serif;
  box-sizing: border-box;
  color: black;
  /* outline: 1px solid gold; */
}

.raleway {
  font-family: 'Raleway', sans-serif;
}

.sourcesanspro {
  font-family: 'Source Sans Pro', sans-serif;
}

.pointer:active {
  opacity: 0.75;
}

.svg-hover:hover {
  opacity: 0.5 !important;
  -webkit-transition: .5s opacity;
  transition: .5s opacity;
}

.svg-hover:active {
  opacity: 1;
  -webkit-transition: .5s opacity;
  transition: .5s opacity;
}

.landing1,
.explore1,
.explore2,
.explore3,
.explore4,
.content1 {
  position: relative;
}

.page__inner,
.explore1,
.explore2,
.explore3,
.explore4 {
  height: 100%;
}

.explore4 {
  overflow: hidden;
}

.read-close {
  position: absolute;
  top: 35px;
  right: 35px;
}

.read-close-legend {
  position: absolute;
  bottom: 50px;
  left: 50%;
  margin-left: -70px;
}

@media only screen and (min-width: 900px) {
  .read-close-legend {
    bottom: 50%;
    margin-left: -90px;
    margin-bottom: -30px;
  }
}

/* Page */

.page {
  height: calc(100vh - 100px);
  /* padding: calc(15% + 1.5em) 5% 5%; */
  overflow-y: auto;
  padding-top: 30px;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: box-shadow 1s ease-in-out, -webkit-transform 1s ease-in-out;
  transition: box-shadow 1s ease-in-out, -webkit-transform 1s ease-in-out;
  transition: transform 1s ease-in-out, box-shadow 1s ease-in-out;
  transition: transform 1s ease-in-out, box-shadow 1s ease-in-out, -webkit-transform 1s ease-in-out;
}

.page h2 {
  font-size: 29px;
}

@media only screen and (min-width: 700px) {
  .page {
    height: calc(100vh - 50px);
    padding-top: 50px;
  }
}

@media only screen and (min-width: 900px) {
  .read {
    max-width: 75%;
    margin: 0 auto;
    padding-top: 75px;
  }
}

@media only screen and (min-width: 1023px) {
  .page {
    height: 100vh;
    padding-top: 50px;
  }
}

.flex {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
}

h1 {
  font-size: 30px;
}

h4 {
  font-size: 14px;
  text-transform: uppercase;
}


@media only screen and (min-width: 900px) {
  h1 {
    font-size: 54px;
  }

  h4 {
    font-size: 24px;
  }

  p {
    font-size: 22px;
  }
}

.right-tap {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 50%;
  right: 15px;
  z-index: 999;
}

.left-tap {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 50%;
  left: 15px;
  z-index: 999;
}

@media only screen and (min-width: 900px) {
  .right-tap {
    right: -10px;
  }

  .left-tap {
    left: -10px;
  }
}

.view {
  height: 100vh;
  /* padding: calc(15% + 1.5em) 5% 5%; */
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
  -webkit-overflow-scrolling: touch;
  -webkit-transition: box-shadow 1s ease-in-out, -webkit-transform 1s ease-in-out;
  transition: box-shadow 1s ease-in-out, -webkit-transform 1s ease-in-out;
  transition: transform 1s ease-in-out, box-shadow 1s ease-in-out;
  transition: transform 1s ease-in-out, box-shadow 1s ease-in-out, -webkit-transform 1s ease-in-out;
}

.view-enter {
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
}

.view-enter-active {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}

.view-exit {
  box-shadow: 0 0 5em 0 rgba(0, 0, 0, 0.5) inset;
  -webkit-transform: translate(100%, 0);
          transform: translate(100%, 0);
}

.page-enter {
  -webkit-transform: translate(100%, 0);
          transform: translate(100%, 0);
}

.page-enter-active {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}

.page-exit {
  box-shadow: 0 0 5em 0 rgba(0, 0, 0, 0.5) inset;
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
}

.page-right.page-enter {
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0);
}

.page-right.page-enter-active {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}

.page-right.page-exit {
  box-shadow: 0 0 5em 0 rgba(0, 0, 0, 0.5) inset;
  -webkit-transform: translate(100%, 0);
          transform: translate(100%, 0);
}

.page-exit .page__inner {
  opacity: 1;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
  -webkit-transition: opacity 1s ease-in-out, -webkit-transform 1s ease-in-out;
  transition: opacity 1s ease-in-out, -webkit-transform 1s ease-in-out;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out, -webkit-transform 1s ease-in-out;
}

.menu {
  height: 100%;
  overflow: hidden;
  background-color: #2d2e7d;
  -webkit-transform: translate(-100%, 0%);
          transform: translate(-100%, 0%);
  pointer-events: none;
  -webkit-transition: 0.5s -webkit-transform;
  transition: 0.5s -webkit-transform;
  transition: 0.5s transform;
  transition: 0.5s transform, 0.5s -webkit-transform;
}

.menu.open {
  -webkit-transition: 0.5s -webkit-transform;
  transition: 0.5s -webkit-transform;
  transition: 0.5s transform;
  transition: 0.5s transform, 0.5s -webkit-transform;
  z-index: 1000;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  pointer-events: auto;
}

.menu.close {
  -webkit-transition: 0.5s -webkit-transform;
  transition: 0.5s -webkit-transform;
  transition: 0.5s transform;
  transition: 0.5s transform, 0.5s -webkit-transform;
  z-index: 1000;
  -webkit-transform: translate(-100%, 0%);
          transform: translate(-100%, 0%);
  pointer-events: auto;
}

.legend {
  -webkit-transition: 0.5s -webkit-transform;
  transition: 0.5s -webkit-transform;
  transition: 0.5s transform;
  transition: 0.5s transform, 0.5s -webkit-transform;
  height: 100%;
  overflow: hidden;
  background-color: #2d2e7d;
  -webkit-transform: translate(100%, 0%);
          transform: translate(100%, 0%);
  pointer-events: none;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  position: fixed; 
  top: 0;
   bottom: 0;
   left: 0;
   right: 0;
}

.legend.open {
  z-index: 1000;
  -webkit-transform: translate(0%, 0%);
          transform: translate(0%, 0%);
  pointer-events: auto;
  -webkit-transition: 0.5s -webkit-transform;
  transition: 0.5s -webkit-transform;
  transition: 0.5s transform;
  transition: 0.5s transform, 0.5s -webkit-transform;
}

.legend.close {
  z-index: 1000;
  -webkit-transition: 0.5s -webkit-transform;
  transition: 0.5s -webkit-transform;
  transition: 0.5s transform;
  transition: 0.5s transform, 0.5s -webkit-transform;
  -webkit-transform: translate(100%, 0%);
          transform: translate(100%, 0%);
  pointer-events: auto;
}

@-webkit-keyframes slideMenuIn {
  0% {
    opacity: 0;
    -webkit-transform: translate(-100%, 0%);
            transform: translate(-100%, 0%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
  }
}

@keyframes slideMenuIn {
  0% {
    opacity: 0;
    -webkit-transform: translate(-100%, 0%);
            transform: translate(-100%, 0%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
  }
}

@-webkit-keyframes slideMenuOut {
  0% {
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
    z-index: 100;
    opacity: 1;
  }
  99% {
    -webkit-transform: translate(-100%, 0%);
            transform: translate(-100%, 0%);
    z-index: 100;
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-100%, 0%);
            transform: translate(-100%, 0%);
    z-index: -1;
    opacity: 0;
  }
}

@keyframes slideMenuOut {
  0% {
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
    z-index: 100;
    opacity: 1;
  }
  99% {
    -webkit-transform: translate(-100%, 0%);
            transform: translate(-100%, 0%);
    z-index: 100;
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(-100%, 0%);
            transform: translate(-100%, 0%);
    z-index: -1;
    opacity: 0;
  }
}

@-webkit-keyframes slideLegendIn {
  0% {
    opacity: 1;
    -webkit-transform: translate(100%, 0%);
            transform: translate(100%, 0%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
  }
}

@keyframes slideLegendIn {
  0% {
    opacity: 1;
    -webkit-transform: translate(100%, 0%);
            transform: translate(100%, 0%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
  }
}

@-webkit-keyframes slideLegendOut {
  0% {
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
    z-index: 100;
  }
  99% {
    -webkit-transform: translate(100%, 0%);
            transform: translate(100%, 0%);
    z-index: 100;
  }
  100% {
    -webkit-transform: translate(100%, 0%);
            transform: translate(100%, 0%);
    z-index: -1;
  }
}

@keyframes slideLegendOut {
  0% {
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
    z-index: 100;
  }
  99% {
    -webkit-transform: translate(100%, 0%);
            transform: translate(100%, 0%);
    z-index: 100;
  }
  100% {
    -webkit-transform: translate(100%, 0%);
            transform: translate(100%, 0%);
    z-index: -1;
  }
}

/* fix iOS bug not displaying 100vh correctly */
/* iPad Pro */
@media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) and (orientation : landscape) {
	.fullheight {
		height: 1024px;
	}
}
@media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) and (orientation : portrait) {
	.fullheight {
		height: 1366px;
	}
}

/* iPad */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
	.fullheight {
		height: 680px;
	}
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
	.fullheight {
    height: 870px;
	}
}


/* iPhone 4 */
/* @media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
	.fullheight {
		height: 320px;
	}
} */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
	.fullheight {
		height: 480px;
	}
}

/* iPhone5 */
/* @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {
	.fullheight {
		height: 320px;
	}
} */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
	.fullheight {
		height: 384px;
	}
}

/* iphone 6, 6s, 7, 8 */
/* @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {
	.fullheight {
		height: 375px;
	}
} */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
	.fullheight {
		height: 480px;
	}
}

/* iPhone 6/7/8 plus */
/* @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {
	.fullheight {
		height: 414px;
	}
} */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
	.fullheight {
		height: 544px;
	}
}

/* iphone X */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
  .fullheight {
		height: 544px;
	}
}

/* iphone XS */
@media only screen and (min-device-width : 375px) and (max-device-height : 812px) and (orientation : portrait) and (-webkit-device-pixel-ratio : 3) {
  .fullheight {
		height: 544px;
	}
}

/* iphone XR */
@media only screen and (min-device-width : 414px) and (max-device-height : 896px) and (orientation : portrait) and (-webkit-device-pixel-ratio : 2) { 
  .fullheight {
		height: 630px;
	}
}

/* iphone XS Max */
 @media only screen and (min-device-width : 414px) and (max-device-height : 896px) and (orientation : portrait) and (-webkit-device-pixel-ratio : 3) { 
  .fullheight {
		height: 630px;
	}
}

.pulse {
  -webkit-animation: pulse 2s infinite;
          animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.ios-fix {
  transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  perspective: 1000;
  -webkit-perspective: 1000;
}

.highlights {
  font-weight: bold;
  font-style: italic;
}

.menu-selected:after {
  position: absolute;
  content: "";
  height: 2px;
  background-color: #FFF;
  width: 60%;
  margin-left: 0px;
  left: 0;
  top: 100%;
} 

.transition-in {
  -webkit-transform: translate(100px, 0);
          transform: translate(100px, 0);
  -webkit-animation: transition-in 2s forwards;
          animation: transition-in 2s forwards;
}

@-webkit-keyframes transition-in {
  0% {
    -webkit-transform: translate(100px, 0);
            transform: translate(100px, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes transition-in {
  0% {
    -webkit-transform: translate(100px, 0);
            transform: translate(100px, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    opacity: 1;
  }
}

.link, .link-white, .link2, .link-lang {
  position: relative;
  cursor: pointer;
}

.active {
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity;
}

.link:hover:after, .link.active:after, .link2:hover:after, .link-lang:hover:after {
  opacity: 1;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity;
}

.link-white:hover:after, .link-white.active:after {
  opacity: 1;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity;
}

.link:after, .active:after, .link-white:after, .link2:after {
  position: absolute;
  content: "";
  height: 3px;
  background-color: #43449a;
  width: 100%;
  margin-left: 0px;
  left: 0;
  top: 20px;
  opacity: 0;
}

.link-lang:after {
  position: absolute;
  content: "";
  height: 3px;
  background-color: #43449a;
  width: 58px;
  margin-left: 0px;
  left: 20px;
  top: 18px;
  opacity: 0;
}

.link2:after {
  background-color: #d88383;
}

.link-white:after {
  background-color: #ffffff;
  top: 32px;
  width: 20%;
}

@media only screen and (min-width: 900px) {
  .link-white:after {
    background-color: #ffffff;
    top: 60px;
    width: 15%;
  }
}

.explore-icon {
  width: 50px;
  height: 50px;
  position: relative;
  margin-top: 15px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.explore-icon-circle {
  width: 35px;
  height: 35px;
  border-radius: 100%;
  -webkit-animation: explore-anim 2s infinite;
          animation: explore-anim 2s infinite;
  border: 1px solid rgba(67,68,154, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
}

.explore-text { 
  font-weight: 700;
  text-align: left;
  font-size: 18px; 
  color: #43449a; 
  letter-spacing: 1px;
  cursor: pointer;
}

@-webkit-keyframes explore-anim {
  0% {
    border-color: rgba(67,68,154, 1);
    width: 35px;
    height: 35px;
  }
  100% {
    border-color: rgba(67,68,154, 0);
    width: 50px;
    height: 50px;
  }
}

@keyframes explore-anim {
  0% {
    border-color: rgba(67,68,154, 1);
    width: 35px;
    height: 35px;
  }
  100% {
    border-color: rgba(67,68,154, 0);
    width: 50px;
    height: 50px;
  }
}

.icon-circle {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  /* animation: icon-anim 2s infinite; */
  border: 1px solid rgba(67,68,154, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
}

.icon-circle-arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1;
  opacity: 1;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity;
}

.icon-circle-arrow:hover {
  opacity: 0.5;
  -webkit-transition: 0.5s opacity;
  transition: 0.5s opacity;
}

@-webkit-keyframes icon-anim {
  0% {
    border-color: rgba(67,68,154, 1);
  }
  100% {
    border-color: rgba(67,68,154, 0);
  }
}

@keyframes icon-anim {
  0% {
    border-color: rgba(67,68,154, 1);
  }
  100% {
    border-color: rgba(67,68,154, 0);
  }
}

.how-to-read {
  width: 100%;
  position: fixed;
  height: calc(100vh - 50px);
}

.how-to-read-image {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  height: calc(100% - 40px);
  width: 90%;
  margin-left: 5%;
}

.SocialMediaShareButton--facebook, .SocialMediaShareButton--twitter {
  border: 1px solid #43449a;
  border-radius: 100%;
  cursor: pointer;
}
